import React from 'react';
import {
  TeliaP,
  TeliaHeading,
  TeliaRow,
  TeliaLink,
} from '@teliads/components/react';
import '../styles/stepsList.scss';
import {
  ContentfulGuideEntity,
  ContentfulStaticTranslations,
} from '../models/ContentfulTypes';
import { useStaticTranslations } from '../hooks/useStaticTranslations';

type StepsType = {
  title: string;
  dividerContentfulId: string;
  description: string;
  language: string;
  steps: ContentfulGuideEntity[];
  navigateTo: () => Promise<void>;
};

const NOOP = () => {};

export const StepsList = ({
  title,
  dividerContentfulId,
  description,
  steps,
  navigateTo,
  language,
}: StepsType) => {
  const translation = useStaticTranslations();
  const partTranslation = translation[language].nodes?.find(
    node => node.contentful_id === ContentfulStaticTranslations.part
  );
  return (
    <TeliaRow className="column gap center padding--vertical">
      <TeliaHeading tag="h2" variant="display-100">
        {title}
      </TeliaHeading>
      <TeliaP>{description}</TeliaP>
      {steps.map((step, index) => (
        <TeliaLink
          key={step.contentful_id}
          className={`telia-link--step`}
          silent
          disableVisited
          onClick={() => {
            dividerContentfulId === step.contentful_id ? navigateTo() : NOOP();
          }}
        >
          <article
            className={
              dividerContentfulId === step.contentful_id
                ? 'step--highlighted'
                : ''
            }
          >
            <TeliaP variant="overline-100">
              {partTranslation?.title} {index + 1}
            </TeliaP>
            <TeliaHeading tag="h3" variant="display-100">
              {step.title}
            </TeliaHeading>
          </article>
        </TeliaLink>
      ))}
    </TeliaRow>
  );
};
